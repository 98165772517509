<template>
  <v-container fluid>
    <v-card flat outlined class="pa-sm-4 wraperx" v-if="dataLoading">
      <v-skeleton-loader type="table"></v-skeleton-loader>
    </v-card>
    <v-card flat outlined class="pa-sm-4 wraperx" v-else>
      <v-card-title class="primary--text">
        Vehicle Running Logs
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          depressed
          outlined
          @click="newItem()"
          v-if="
            $_checkPermission(
              this.auth.permissions,
              'Manage Vehicle Running Logs',
              'Overrule'
            )
          "
        >
          <v-icon left> mdi-plus </v-icon> New Log - Overrule
        </v-btn>
      </v-card-title>
      <v-card-title
        class="justify-center justify-sm-start"
        v-if="
          $_checkPermission(
            this.auth.permissions,
            'Manage Vehicle Running Logs',
            'Read All'
          ) ||
          $_checkPermission(
            this.auth.permissions,
            'Manage Vehicle Running Logs',
            'Read Sub'
          )
        "
      >
        <v-card outlined>
          <v-tabs
            v-model="tab"
            @change="changeView"
            background-color="transparent"
          >
            <v-tooltip bottom max-width="200">
              <template v-slot:activator="{ on, attrs }">
                <v-tab
                  style="
                    overflow-x: hidden !important;
                    word-break: keep-all;
                    text-overflow: ellipsis !important;
                    max-width: calc(50vw - 50px);
                    min-width: 0 !important;
                  "
                  v-bind="attrs"
                  v-on="on"
                  :tab-value="1"
                  >My</v-tab
                >
              </template>
              <span>View your running logs</span>
            </v-tooltip>
            <v-divider
              vertical
              v-if="
                $_checkPermission(
                  this.auth.permissions,
                  'Manage Vehicle Running Logs',
                  'Read Sub'
                )
              "
            ></v-divider>
            <v-tooltip
              bottom
              max-width="200"
              v-if="
                $_checkPermission(
                  this.auth.permissions,
                  'Manage Vehicle Running Logs',
                  'Read Sub'
                )
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-tab
                  style="
                    overflow-x: hidden !important;
                    word-break: keep-all;
                    text-overflow: ellipsis !important;
                    max-width: calc(50vw - 50px);
                    min-width: 0 !important;
                  "
                  v-bind="attrs"
                  v-on="on"
                  :tab-value="3"
                  >Sub</v-tab
                >
              </template>
              <span>View running logs of your subordinates</span>
            </v-tooltip>

            <v-divider
              vertical
              v-if="
                $_checkPermission(
                  this.auth.permissions,
                  'Manage Vehicle Running Logs',
                  'Read All'
                )
              "
            ></v-divider>
            <v-tooltip
              bottom
              max-width="200"
              v-if="
                $_checkPermission(
                  this.auth.permissions,
                  'Manage Vehicle Running Logs',
                  'Read All'
                )
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-tab
                  style="
                    overflow-x: hidden !important;
                    word-break: keep-all;
                    text-overflow: ellipsis !important;
                    max-width: calc(50vw - 50px);
                    min-width: 0 !important;
                  "
                  v-bind="attrs"
                  v-on="on"
                  :tab-value="2"
                  >All</v-tab
                >
              </template>
              <span>View all running logs</span>
            </v-tooltip>
          </v-tabs>
        </v-card>
      </v-card-title>
      <v-card-title class="primary--text">
        <v-col style="min-width: 200px">
          <v-menu
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="auto"
            v-model="menu1"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                filled
                flat
                outlined
                label="From (Date)"
                v-model="startDate"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                clearable
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              color="primary"
              no-title
              @input="menu1 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col style="min-width: 200px">
          <v-menu
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="auto"
            v-model="menu2"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                filled
                flat
                outlined
                label="To (Date)"
                v-model="endDate"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                clearable
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              color="primary"
              no-title
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col style="min-width: 200px">
          <v-autocomplete
            filled
            multiple
            flat
            v-model="projectUuids"
            :items="projects"
            item-text="code"
            item-value="uuid"
            label="Projects"
            outlined
            dense
            clearable
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col style="min-width: 200px">
          <v-autocomplete
            filled
            multiple
            flat
            v-model="vehicleUuids"
            :items="vehicles"
            item-text="vehicle_no"
            item-value="uuid"
            label="Vehicles"
            outlined
            dense
            clearable
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col style="min-width: 200px">
          <v-autocomplete
            filled
            multiple
            flat
            v-model="staffUuids"
            :items="staff"
            item-text="name"
            item-value="uuid"
            label="Subordinates"
            outlined
            dense
            clearable
            hide-details
          >
            <template v-slot:selection="{ item, index }">
              <span v-if="index === 0" class="me-1">{{ item.name }}</span>
              <span v-if="index === 1" class="grey--text text-caption">
                (+{{ staffUuids.length - 1 }} others)
              </span>
            </template></v-autocomplete
          >
        </v-col>
        <v-col style="min-width: 200px">
          <v-autocomplete
            filled
            multiple
            flat
            v-model="reviewStatus"
            :items="reviewStatuses"
            label="Status"
            outlined
            dense
            clearable
            hide-details
          ></v-autocomplete>
        </v-col>
      </v-card-title>
      <v-data-table
        mobile-breakpoint="0"
        :headers="headers"
        :items="items.data"
        :items-per-page.sync="limit"
        :page.sync="page"
        :server-items-length="items.totalCount"
        :loading="loading"
        loading-text="Loading..."
      >
        <template v-slot:item.review_status="{ item }">
          <v-chip
            small
            dark
            :color="
              item.review_status == 'APPROVED'
                ? 'green'
                : item.review_status == 'REJECTED'
                ? 'error'
                : 'amber darken-2'
            "
            >{{
              item.review_status == "APPROVED"
                ? "Approved"
                : item.review_status == "REJECTED"
                ? "Rejected"
                : "Pending"
            }}</v-chip
          >
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            v-if="item.edit_status == 'DISABLED'"
            depressed
            small
            color="primary"
            class="mx-0 px-0 mt-1"
            dark
            @click="viewItem(item)"
          >
            more
          </v-btn>
          <v-badge
            bordered
            :color="
              item.edit_status == 'REQUEST_WAITING'
                ? 'orange'
                : item.edit_status == 'REQUEST_REJECTED'
                ? 'error'
                : 'green'
            "
            dot
            overlap
            v-else
          >
            <v-btn
              depressed
              small
              color="primary"
              class="mx-0 px-0 mt-1"
              dark
              @click="viewItem(item)"
            >
              more
            </v-btn>
          </v-badge>
        </template>
        <template v-slot:item.description="{ item }">
          <v-sheet class="text-truncate" max-width="200" color="transparent">
            {{ item.description }}
          </v-sheet>
        </template>
        <template v-slot:item.dispatch_km="{ item }">
          {{ item.dispatch_km }}
          <v-chip
            color="orange"
            dark
            x-small
            v-if="
              item.dispatch_km != item.prev_return_km &&
              vehicleUuids.length == 1
            "
            >non-sequential</v-chip
          >
        </template>
        <template v-slot:item.staff.first_name="{ item }">
          {{
            item.staff
              ? item.staff.first_name + " " + item.staff.last_name
              : "Not assign"
          }}
        </template>
      </v-data-table>
      <v-card-actions>
        <v-sheet
          class="d-flex align-center text-caption px-2"
          color="transparent"
        >
          <v-badge dot color="orange" class="me-3"></v-badge> Edit Access
          Requested
        </v-sheet>
        <v-sheet
          class="d-flex align-center text-caption px-2"
          color="transparent"
        >
          <v-badge dot color="red" class="me-3"></v-badge> Edit Access Rejected
        </v-sheet>
        <v-sheet
          class="d-flex align-center text-caption px-2"
          color="transparent"
        >
          <v-badge dot color="green" class="me-3"></v-badge> Edit Access Granted
        </v-sheet>
      </v-card-actions>
      <v-card-actions class="d-flex align-center text-caption px-2">
        To identify any gaps in meter readings, please select a specific
        vehicle. You can apply additional filters as needed.
      </v-card-actions>
    </v-card>
  </v-container>
</template>
    
    
    <script>
import { mapState } from "vuex";
import { fetchAllVehicleRunning } from "@/services/logsService";
import {
  fetchAndStoreStaffData,
  fetchAndStoreVehiclesData,
  fetchAndStoreProjectsData,
} from "@/services/preloadDataService";
export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  watch: {
    limit: {
      handler() {
        this.fetchLogs();
      },
      deep: true,
    },
    page: {
      handler() {
        this.fetchLogs();
      },
      deep: true,
    },
    startDate: {
      handler() {
        this.fetchLogs();
      },
      deep: true,
    },
    endDate: {
      handler() {
        this.fetchLogs();
      },
      deep: true,
    },
    vehicleUuids: {
      handler() {
        this.fetchLogs();
      },
      deep: true,
    },
    staffUuids: {
      handler() {
        this.fetchLogs();
      },
      deep: true,
    },
    projectUuids: {
      handler() {
        this.fetchLogs();
      },
      deep: true,
    },
    reviewStatus: {
      handler() {
        this.fetchLogs();
      },
      deep: true,
    },
  },
  data: () => ({
    menu1: false,
    menu2: false,
    tab: 3,
    dataLoading: false,
    loading: false,
    headers: [
      {
        text: "Log Id",
        value: "id",
        sortable: false,
      },
      {
        text: "Date",
        value: "date",
        sortable: false,
      },
      { text: "Vehicle", value: "vehicles.vehicle_no", sortable: false },
      { text: "Project", value: "projects.code", sortable: false },
      { text: "Driver", value: "staff.first_name", sortable: false },
      { text: "Dispatch (km)", value: "dispatch_km", sortable: false },
      { text: "Return (km)", value: "return_km", sortable: false },
      { text: "Total (km)", value: "total_km", sortable: false },
      { text: "Status", value: "review_status", sortable: false },
      { text: "Action", value: "actions", sortable: false },
    ],
    items: [],
    page: 1,
    limit: 10,
    startDate: "",
    endDate: "",
    vehicleUuids: [],
    staffUuids: [],
    projectUuids: [],
    vehicles: [],
    projects: [],
    staff: [],
    reviewStatus: [],
    reviewStatuses: ["APPROVED", "REJECTED", "PENDING"],
  }),
  async created() {
    if (
      this.$_checkPermission(
        this.auth.permissions,
        "Manage Vehicle Running Logs",
        "Read Sub"
      )
    ) {
      this.dataLoading = true;
      this.vehicles = await fetchAndStoreVehiclesData();
      this.projects = await fetchAndStoreProjectsData();
      this.staff = await fetchAndStoreStaffData();
      this.staff.forEach((person) => {
        person["name"] = person.first_name + " " + person.last_name;
      });
      await this.fetchLogs();
      this.dataLoading = false;
    } else {
      this.$router.push({
        name: "PageDashboard",
      });
    }
  },
  mounted() {
    //
  },
  methods: {
    viewItem(item) {
      this.$router.push({
        name: "PageViewVehicleRunning",
        params: { uuid: item.uuid },
        query: { bc: item.id, mylog: "false" },
      });
    },
    newItem() {
      this.$router.push({
        name: "PageNewVehicleRunning",
        query: { overrule: "true" },
      });
    },
    changeView() {
      if (this.tab == 1) {
        this.$router.push({
          name: "PageMyVehicleRunningList",
        });
      }
      if (this.tab == 2) {
        this.$router.push({
          name: "PageAllVehicleRunningList",
        });
      }
    },
    async fetchLogs() {
      this.loading = true;
      if (this.$_getSubordinates(this.staff, this.auth.uuid).length > 0) {
        if (this.staffUuids.length == 0) {
          this.staffUuids = this.$_getSubordinates(this.staff, this.auth.uuid);
          const filteredArray = this.staff.filter((item) =>
            this.staffUuids.includes(item.uuid)
          );
          this.staff = filteredArray;
        }
      } else {
        this.staff = [];
      }
      if (this.staffUuids.length > 0) {
        this.items = await fetchAllVehicleRunning(
          this.page,
          this.limit,
          this.startDate ? this.startDate : "",
          this.endDate ? this.endDate : "",
          JSON.stringify(this.vehicleUuids),
          JSON.stringify(this.staffUuids),
          JSON.stringify(this.projectUuids),
          JSON.stringify(this.reviewStatus)
        );
      } else {
        this.items = [];
      }
      if (this.vehicleUuids.length == 1) {
        this.items.data = this.$_.orderBy(
          this.items.data,
          ["dispatch_km"],
          ["asc"]
        );
        this.items.data.forEach((log, i) => {
          if (i == 0) {
            log["prev_return_km"] = log.dispatch_km;
          } else {
            log["prev_return_km"] = this.items.data[i - 1].return_km;
          }
        });
      }
      this.loading = false;
    },
  },
};
</script>